import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { getCompany, updateCompany } from "../../../services/company";
import Loader from "../../Loader";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { useNavigate } from "react-router-dom";
import { getObjectFromLocalStorage } from "../../../api/localStorage";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";
import { Tooltip } from "react-tooltip";

function Branding() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const [isShowBranding, setIsShowBranding] = useState(false);
  const [company, setCompany] = useState(null);

  const fetchUpdateCompany = async (e) => {
    e.preventDefault();

    if (!isCompanyAdmin()) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Available only for company admins",
        },
      });
      setIsShowBranding(company.showBranding);
      return;
    }

    setIsUpdating(true);

    const response = await updateCompany({
      showBranding: isShowBranding,
    });

    if (!response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.message,
        },
      });
      setIsShowBranding(company.showBranding);
      setIsUpdating(false);
      return;
    }

    setCompany({ ...company, showBranding: isShowBranding });

    setIsUpdating(false);
  };

  const isDataNotChanged = () => {
    return company.showBranding === isShowBranding;
  };

  const isCompanyAdmin = () => {
    const user = getUserFromLocalStorage();
    return user?.role === "company_admin" || user?.role === "admin";
  };

  useEffect(() => {
    const fetchCompany = async () => {
      const response = await getCompany();

      if (!response.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: response.message,
          },
        });

        navigate("/campaigns");
        setIsLoading(false);
        return;
      }

      setCompany(response.company);
      setIsShowBranding(response.company.showBranding);

      setIsLoading(false);
    };

    const companyInfo = getObjectFromLocalStorage("companyInfo");

    if (
      companyInfo &&
      companyInfo.company &&
      companyInfo.company.showBranding
    ) {
      setCompany(companyInfo.company);
      setIsShowBranding(companyInfo.company.showBranding);
      setIsLoading(false);
      return;
    }

    fetchCompany();
  }, []);

  return (
    <Content>
      {isLoading ? (
        <Loader parentSize={true} />
      ) : (
        <Section>
          <Title>Branding</Title>
          <Inputs>
            <InputBlock>
              <CheckboxBlock
                data-tooltip-id={!isCompanyAdmin() ? `no-admin-tooltip` : null}
                data-tooltip-content={
                  !isCompanyAdmin()
                    ? "Please contact your company admin to change this setting"
                    : null
                }
              >
                <input
                  id="isShowBrandingCheckbox"
                  type="checkbox"
                  checked={isShowBranding}
                  value={isShowBranding}
                  disabled={!isCompanyAdmin()}
                  onChange={(event) => setIsShowBranding(event.target.checked)}
                />
                <label
                  htmlFor="isShowBrandingCheckbox"
                  className="toggle"
                ></label>
                <CheckboxLabel $checked={isShowBranding}>
                  Show branding
                </CheckboxLabel>
              </CheckboxBlock>
              {!isCompanyAdmin() ? (
                <Tooltip
                  id={`no-admin-tooltip`}
                  className="custom-tooltip"
                  place="left"
                />
              ) : null}
            </InputBlock>
          </Inputs>

          <Actions>
            <UpdateBtn
              onClick={(e) => fetchUpdateCompany(e)}
              $disabled={isDataNotChanged() || isUpdating}
            >
              Update
            </UpdateBtn>
          </Actions>
        </Section>
      )}
    </Content>
  );
}

export default Branding;

const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Section = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 43px 0 43px 63px;
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const UpdateBtn = styled.button`
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  height: 40px;
  width: 122px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  opacity: ${({ $disabled }) => ($disabled ? "0.5" : "1")};
`;

const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.div`
  font-size: 12px;
  color: ${({ $checked }) => ($checked ? "#000000" : "#808080")};
  font-weight: 400;
  margin-left: 10px;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;
